.Wrap {

}

.Wrap p {
    line-height: 2.0em;
    font-size: 20px;
}

.Wrap ul {
    margin: 30px 0;
    font-size: 18px;
}

.Wrap ul li {
    margin-top: 15px;
    margin-bottom: 15px;
}

.Wrap img {
    width: 100%;
}

hr {
    opacity: 0.2;
}

.Comment {
    margin-top: 60px;
    margin-bottom: 40px;
}

.Comment iframe {
    width: 100%;
}
