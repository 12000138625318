.Wrap {
   position: relative;
}

.BackgroundWrap {
    position: absolute;
    margin: 100px auto;
    text-align: center;
}

.BackGroundImage {
    width: 50%;
    animation: Rotation 120s infinite linear;
    opacity: 0.5;
}

@keyframes Rotation {
    0%{
        transform:rotateZ(0);
    }
    100%{
        transform:rotateZ(-360deg);
    }
}
