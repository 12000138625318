@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
  color: black;
}

.App {
    max-width: 700px;
    margin: 70px auto;
    padding: 0 2em 0 2em;
}

.Logo_Logo__2MvHI {
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: Logo_PlanetRotation__1n4un 60s infinite linear;
    border-radius:50%;
    overflow:hidden;
    box-shadow: 0 0 60px -20px rgba(36, 36, 36, 0.72), -14px -15px 40px -10px rgba(0, 0, 0, 0.23);
}

.Logo_Background__3O-lk {
    width:150px;
    height:150px;
    position:absolute;
    border-radius:50%;
    animation: Logo_BackgroundRotation__2ns4A 120s infinite linear;
    opacity: 0.8;
    box-shadow:inset -10px -10px 40px #bfbfbf, inset 10px 10px 30px -10px rgba(114, 114, 114, 0.6);
}

.Logo_Clouds__3XaQB {
    width:150px;
    height:150px;
    position:absolute;
    border-radius:50%;
    animation: Logo_BackgroundRotation__2ns4A 120s infinite linear;
    opacity: 0.4;
}

@keyframes Logo_PlanetRotation__1n4un {
    0%{
        transform:rotateZ(0);
    }
    100%{
        transform:rotateZ(-360deg);
    }
}

@keyframes Logo_BackgroundRotation__2ns4A {
    0%{
        background-position-x:0;
    }
    50%{
        background-position-x:-600px;
    }
    100%{
        background-position-x:0px;
    }
}

.Logo_Tail__2sVTf {
    width: 150px;
    height: 150px;
    border-radius: 150px;
    transform: rotateZ(70deg) rotateY(70deg) translateZ(-35px) translateY(-7px);
    border: 10px solid #505050;
    position: absolute;
    top: 0;
    left: 0;
}
.Header_List__1N2CS {
    list-style: none;
    display: flex;
    padding: 0;
}

.Header_Item__13V2R {
    font-weight: lighter;
    margin-right: 1em;
    cursor: pointer;
}

.Header_Item__13V2R:hover {
    text-decoration: underline;
}
.PageLink_Link__3IDkO a:link {
    color: gray;
}

.PageLink_Link__3IDkO a:visited {
    color: gray;
}

.PageLink_Link__3IDkO a:hover {
    color: gray;
}

.PageLink_Link__3IDkO a:active {
    color: gray;
}
.About_ImgWrap__15VcY {
    display: flex;
    justify-content: center;
}

.About_ImgWrap__15VcY img {
    width: 200px;
    height: 200px;
    border-radius: 600px;
    object-fit: cover;
}

.About_Profile__2nb1d {
    font-size: 0.8em;
}

.About_Profile__2nb1d ul {
    list-style: none;
    text-align: center;
    padding-left: 0;
}


@media screen and (max-width: 700px) {
    .About_Profile__2nb1d {
        font-size: 0.6em;
    }
}
.ArticleList_Wrap__3UfO0 {
    margin-top: 40px;
}

.ArticleList_Divider__9PJub {
    border-top: none;
    border-bottom: 1px solid #eee;
}

.ArticleList_Item__1ng2b {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.ArticleList_CreateDate__3rcRd {
    white-space: nowrap;
    margin-left: 20px;
}
.Caption_Caption__2CloX {
    font-size: 11px;
    font-weight: lighter;
}
.Home_Wrap__2HGEc {
   position: relative;
}

.Home_BackgroundWrap__1p9VJ {
    position: absolute;
    margin: 100px auto;
    text-align: center;
}

.Home_BackGroundImage__2JCYh {
    width: 50%;
    animation: Home_Rotation__2TR-R 120s infinite linear;
    opacity: 0.5;
}

@keyframes Home_Rotation__2TR-R {
    0%{
        transform:rotateZ(0);
    }
    100%{
        transform:rotateZ(-360deg);
    }
}

.Background_Canvas__1VHkg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
}
.ArticleContent_Wrap__2YfSd {

}

.ArticleContent_Wrap__2YfSd p {
    line-height: 2.0em;
    font-size: 20px;
}

.ArticleContent_Wrap__2YfSd ul {
    margin: 30px 0;
    font-size: 18px;
}

.ArticleContent_Wrap__2YfSd ul li {
    margin-top: 15px;
    margin-bottom: 15px;
}

.ArticleContent_Wrap__2YfSd img {
    width: 100%;
}

hr {
    opacity: 0.2;
}

.ArticleContent_Comment__3K_Lt {
    margin-top: 60px;
    margin-bottom: 40px;
}

.ArticleContent_Comment__3K_Lt iframe {
    width: 100%;
}

