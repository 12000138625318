.Wrap {
    margin-top: 40px;
}

.Divider {
    border-top: none;
    border-bottom: 1px solid #eee;
}

.Item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.CreateDate {
    white-space: nowrap;
    margin-left: 20px;
}