.ImgWrap {
    display: flex;
    justify-content: center;
}

.ImgWrap img {
    width: 200px;
    height: 200px;
    border-radius: 600px;
    object-fit: cover;
}

.Profile {
    font-size: 0.8em;
}

.Profile ul {
    list-style: none;
    text-align: center;
    padding-left: 0;
}


@media screen and (max-width: 700px) {
    .Profile {
        font-size: 0.6em;
    }
}